import { useAllPermissions } from '~/src/context/permissions/permissions'
import type { AccessPermissionPossibleTypes } from '~/src/types/menu'
import { useLoadUser } from '../session/use-load-user'

export const useAccessPermissionFn = () => {
  const { user } = useLoadUser()
  const permissions = useAllPermissions()

  return (accessPermission: AccessPermissionPossibleTypes) => {
    if (Array.isArray(accessPermission)) {
      return accessPermission.every((permission) =>
        typeof permission === 'function'
          ? permission(user)
          : !permission || permissions?.[permission],
      )
    }

    if (typeof accessPermission === 'function') {
      return accessPermission(user)
    }

    return !accessPermission || permissions?.[accessPermission]
  }
}
