import { ROUTES } from '~/src/constants'
import type { SessionRichUser } from '~/src/hooks/session/use-load-user'
import type { AccessPermissionPossibleTypes } from '~/src/types/menu'

export type MenuConfigItemTopMenu = {
  id: MenuCategories
  translateKey: string
  href: string
  accessPermissions?: AccessPermissionPossibleTypes
}

export type MenuConfigTopMenu = {
  items: MenuConfigItemTopMenu[]
}

export enum MenuCategories {
  enterprise = 'enterprise',
  jackpots = 'jackpots',
  help = 'help',
  visualisation = 'visualisation',
  store = 'store',
}

const topMenuConfig: MenuConfigTopMenu = {
  items: [
    {
      id: MenuCategories.enterprise,
      translateKey: 'header:enterprise',
      href: ROUTES.companies,
    },
    {
      id: MenuCategories.jackpots,
      translateKey: 'header:jackpots',
      href: ROUTES.jackpots,
    },
    {
      id: MenuCategories.help,
      translateKey: 'header:help',
      href: ROUTES.about,
    },
    {
      id: MenuCategories.store,
      translateKey: 'header:store',
      href: ROUTES.bundleOffers,
    },
    {
      id: MenuCategories.visualisation,
      translateKey: 'header:visualisation',
      href: ROUTES.configuration,
      accessPermissions: (user: SessionRichUser) => user.isJA,
    },
  ],
}

export const getMenuConfig = (): MenuConfigTopMenu => topMenuConfig
