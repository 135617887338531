import { AccessPermissions } from '../__generated__/graphql'
import { ROUTES } from '../constants'
import type { SessionRichUser } from '../hooks/session/use-load-user'
import type { MenuConfig } from '../types/menu'
import { MenuCategories } from './top-menu'

const subMenuConfig: MenuConfig = {
  [MenuCategories.enterprise]: {
    items: [
      {
        id: 'integrants',
        translateKey: 'submenu:integrants',
        href: ROUTES.integrants,
        accessPermissions: AccessPermissions.GetIntegrantList,
      },
      {
        id: 'companies',
        translateKey: 'submenu:companies',
        href: ROUTES.companies,
        accessPermissions: AccessPermissions.GetClientList,
      },
      {
        id: 'users',
        translateKey: 'submenu:users',
        href: ROUTES.users,
        accessPermissions: AccessPermissions.GetUserList,
      },
      {
        id: 'products',
        translateKey: 'submenu:products',
        href: ROUTES.products,
        /**
         * https://jira.softswiss.net/browse/JSA-4459
         * Section is manually hidden if no agreements permissions
         */
        accessPermissions: [AccessPermissions.GetProductList, AccessPermissions.GetAgreements],
      },
      {
        id: 'service-invoices',
        translateKey: 'submenu:service-invoices',
        href: ROUTES.serviceInvoices,
        accessPermissions: AccessPermissions.GetServiceInvoicesList,
      },
      {
        id: 'teams',
        translateKey: 'submenu:teams',
        href: ROUTES.teams,
        accessPermissions: AccessPermissions.GetTeamList,
      },
      {
        id: 'systems',
        translateKey: 'submenu:systems',
        href: ROUTES.systems,
        accessPermissions: AccessPermissions.GetSystemList,
      },
      {
        id: 'games',
        translateKey: 'submenu:games',
        href: ROUTES.games,
        accessPermissions: AccessPermissions.GetGameList,
      },
      {
        id: 'locations',
        translateKey: 'submenu:locations',
        href: ROUTES.locations,
        accessPermissions: AccessPermissions.GetLocationList,
      },
      {
        id: 'analytics',
        translateKey: 'submenu:analytics',
        href: ROUTES.analytics,
        accessPermissions: AccessPermissions.GetReportList,
      },
    ],
  },
  [MenuCategories.jackpots]: {
    items: [
      {
        id: 'jackpots',
        translateKey: 'submenu:jackpots',
        href: ROUTES.jackpots,
        accessPermissions: AccessPermissions.GetJackpotList,
      },
      {
        id: 'levels',
        translateKey: 'submenu:levels',
        href: ROUTES.levels,
        accessPermissions: AccessPermissions.GetLevelList,
      },
      {
        id: 'hits',
        translateKey: 'submenu:hits',
        href: ROUTES.hits,
        accessPermissions: AccessPermissions.GetHitList,
      },
      {
        id: 'templates',
        translateKey: 'submenu:templates',
        href: ROUTES.templates,
        /**
         * https://jira.softswiss.net/browse/JSA-4459
         * Section is manually hidden if no create jackpot campaign permissions
         */
        accessPermissions: [AccessPermissions.GetTemplateList, AccessPermissions.CreateJackpot],
      },
    ],
  },
  [MenuCategories.help]: {
    items: [
      {
        id: 'about',
        translateKey: 'submenu:about',
        href: ROUTES.about,
      },
      {
        id: 'currencies',
        translateKey: 'submenu:currencies',
        href: ROUTES.currencies,
      },
      {
        id: 'widget-demo',
        translateKey: 'submenu:widget-demo',
        href: ROUTES.widgetDemo,
        accessPermissions: (user: SessionRichUser) => user.isJA,
      },
    ],
  },
  [MenuCategories.visualisation]: {
    items: [
      {
        id: 'configuration',
        translateKey: 'submenu:configuration',
        href: ROUTES.configuration,
      },
    ],
  },
  [MenuCategories.store]: {
    items: [
      {
        id: 'bundle-offers',
        translateKey: 'submenu:bundle-offers',
        href: ROUTES.bundleOffers,
        accessPermissions: AccessPermissions.GetBundleOffers,
      },
    ],
  },
}

export const getSubMenuConfig = (): MenuConfig => subMenuConfig
