import { AppBar, Toolbar } from '@mui/material'
import { type CustomTheme, styled } from '@mui/material/styles'
import type { FC, PropsWithChildren } from 'react'

const StyledToolbar = styled(Toolbar)(({ theme }: { theme?: CustomTheme }) => ({
  height: theme?.constants?.headerHeight,
}))

const Header: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppBar color="transparent" position="static">
      <StyledToolbar data-cy="page-toolbar">{children}</StyledToolbar>
    </AppBar>
  )
}

export default Header
